import styled from 'styled-components';
import { theme } from '@brighte/brighte-one-native';

const CallUs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-style: solid;
  border-color: ${theme.FormElements};
  border-width: 1px;
  border-radius: ${theme.blockBorderRadius}px;
  padding: 20px;
  ${props => props.customStyle}
`;

const IconCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.customStyle};
`;

const TitleCell = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.customStyle};
`;

const TextCell = styled.div`
  flex: 4;
  padding-left: 20px;
  justify-content: center;
  ${props => props.customStyle}
`;

CallUs.IconCell = IconCell;
CallUs.TitleCell = TitleCell;
CallUs.TextCell = TextCell;

CallUs.defaultProps = {
  theme: {
    blockBorderRadius: theme.blockBorderRadius,
    FormElements: theme.FormElements,
  },
};

export default CallUs;
