export interface IConfig {
  formId: string;
  appUrl: () => string;
  serviceUrl: () => string;
  portalUrl: () => string;
  financeCoreUrl: () => string;
  responsiveBreakPoint: string;
  sentryUrl: string;
  timeout: number;
  appType: string;
  appSecret: string;
  cpayScope: Array<string>;
}

export interface CpayResponse {
  responseText: string;
  token: string;
  cardHolder: string;
  cardNumber: string;
  cardType: string;
  cardExpiry: string;
}

export interface PaymentMethod {
  type: PaymentMethodType;
}

export interface BankAccount extends PaymentMethod {
  accountBsb: string;
  accountLast4: string;
  accountName: string;
}

export interface CreditCard extends PaymentMethod {
  cardType: PaymentCardType;
  cardNumber: string;
  cardExpiry: string;
}

export enum PaymentMethodType {
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum PaymentCardType {
  VISA = 'VISA',
  MasterCard = 'MasterCard',
}

export interface RemoteIdAuth {
  remoteId: string;
  authCode?: string;
  scope?: Array<string>;
}

export interface Identity {
  user: Record<string, any>;
  scope: Array<string>;
  accessToken: string;
  refreshToken: string;
  needRefresh?: boolean;
}

export const isIdentity = (object: any): object is Identity => {
  return Boolean(object && object.user && object.scope && object.accessToken && object.refreshToken && object);
};

export type Page = {
  pageName: string;
  page: any;
};
