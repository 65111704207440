interface ERRORS {
  TIMEOUT: {
    CODE: string;
  };
  INVALID_URL: {
    CODE: string;
    SHOW_REFRESH_BUTTON: boolean;
    MESSAGE_TITLE: string;
    MESSAGE: string;
  };
}

interface DEFAULTERROR {
  MESSAGE_TITLE: string;
  SHOW_REFRESH_BUTTON: boolean;
  MESSAGE: string;
}

interface SENTRYERROR {
  LEVEL: {
    //Errors that we need to be aware of in sentry, and this is also used to setLevel of error
    ERROR: string;
    FATAL: string;
  };
  SEVERITY: {
    // production incident occurred, our ability to take a loan application compromised highest priority
    EMERGENCY: string;
    // 	highest level incident which does not affect our ability to take an application but concerns the processing of loans
    ALERT: string;
    // highest level incident which does not affect loans
    CRITICAL: string;
    // error we need to be aware of, but one that might not affect the flow of current script/process/workflow
    ERROR: string;
  };
}
const ERROR: ERRORS = {
  TIMEOUT: {
    CODE: 'Timeout',
  },
  INVALID_URL: {
    CODE: 'InvalidUrl',
    SHOW_REFRESH_BUTTON: false,
    MESSAGE_TITLE: 'Oops!',
    MESSAGE: 'The link appears to be incorrect. Please try opening the link sent to you again.',
  },
};

const DEFAULT_ERROR: DEFAULTERROR = {
  MESSAGE_TITLE: 'Oops, something just snapped!',
  SHOW_REFRESH_BUTTON: true,
  MESSAGE:
    'But don’t worry your information is safe. Simply click on refresh button below to continue where' +
    ' you left off, or call us on 1300-274-448 to complete your application. We apologise for the inconvenience.',
};

const SENTRY_ERROR: SENTRYERROR = {
  LEVEL: {
    ERROR: 'Error',
    FATAL: 'Fatal',
  },
  SEVERITY: {
    EMERGENCY: 'Emergency',
    ALERT: 'Alert',
    CRITICAL: 'Critical',
    ERROR: 'Error',
  },
};

export { ERROR, DEFAULT_ERROR, SENTRY_ERROR };
