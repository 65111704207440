import { gql, useLazyQuery } from '@apollo/client';

export const ACCOUNT_DETAILS = gql`
  query FinanceAccount($id: String!) {
    financeAccount(id: $id) {
      id
      balanceOwing
      nextPayment
      repaymentAmount
      balance
      financialProduct {
        type
        slug
        fpBranch
      }
      paymentMethod {
        paymentMethod
        creditCardExpiry
        maskedCreditCardInfo
        bankBsb
        maskedBankAccountNumber
      }
      productConfiguration {
        weeklyAccountFee
      }
    }
  }
`;

export function useAccountDetails(applicationId, onCompletedCallback) {
  const [loadAccountDetails, { loading, error, data }] = useLazyQuery(ACCOUNT_DETAILS, {
    variables: { id: applicationId },
    onCompleted: onCompletedCallback,
  });

  return { loading, data: data?.financeAccount, error, loadAccountDetails };
}
