import get from 'lodash/get';

export const loadState = (storeId: string, key?: string | undefined) => {
  if (!storeId) {
    return undefined;
  }

  try {
    let serializedState = window.localStorage.getItem(storeId);

    if (key) {
      serializedState = get(serializedState, key, '{}');
    }

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (
  storeId: string,
  key: string,
  state: any,
  condition?: (store: Record<string, any>) => boolean,
) => {
  if (!storeId) {
    return window.localStorage.clear();
  }

  if (condition && !condition(state)) {
    return;
  }

  try {
    const serializedState = JSON.stringify(get(state, key, {}));
    window.localStorage.setItem(storeId, serializedState);
  } catch (err) {
    console.warn(`Problem to save redux state to local storage ${err}`);
  }
};
