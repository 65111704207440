import { IConfig } from './types';

const Config: IConfig = {
  formId: 'brighteOneOffPayment',
  responsiveBreakPoint: 'XS',
  sentryUrl: 'https://2e863d6e854149ef9682b79bfec333c3@o32608.ingest.sentry.io/5191687',
  timeout: 30000, // global restful api call timeout, 30 seconds by default
  appType: 'BOOP',
  appSecret: 'notarealsecret',
  appUrl: () => {
    switch (process.env.NODE_ENV) {
      case 'staging':
        return 'https://paynow.staging.cloud.brighte.com.au/';
      case 'uat':
        return 'https://paynow.uat.cloud.brighte.com.au/';
      case 'production':
        return 'https://paynow.brighte.com.au/';
      default:
        return 'http://localhost:3000/';
    }
  },
  serviceUrl: () => {
    switch (process.env.NODE_ENV) {
      case 'staging':
        return 'https://api.staging.cloud.brighte.com.au/v1';
      case 'uat':
        return 'https://api.uat.cloud.brighte.com.au/v1';
      case 'production':
        return 'https://api.brighte.com.au/v1';
      default:
        return 'https://api.staging.cloud.brighte.com.au/v1';
    }
  },
  portalUrl: () => {
    switch (process.env.NODE_ENV) {
      case 'staging':
        return 'https://portal.staging.cloud.brighte.com.au';
      case 'uat':
        return 'https://portal.uat.cloud.brighte.com.au';
      case 'production':
        return 'https://portal.brighte.com.au';
      default:
        return 'https://portal.staging.cloud.brighte.com.au';
    }
  },
  cpayScope: [
    'read:finance-accounts',
    'read:payment-methods',
    'write:payment-methods',
    'write:payments',
    'write:payment-gateway',
  ],
  financeCoreUrl: () => {
    switch (process.env.NODE_ENV) {
      case 'staging':
        return 'https://api.staging.cloud.brighte.com.au/v2/finance';
      case 'uat':
        return 'https://api.uat.cloud.brighte.com.au/v2/finance';
      case 'production':
        return 'https://api.brighte.com.au/v2/finance';
      default:
        return 'https://api.staging.cloud.brighte.com.au/v2/finance';
    }
  },
};

export const defaultHeaders = {
  accept: '*/*',
  'content-type': 'application/json',
  'sec-fetch-dest': 'empty',
  'sec-fetch-mode': 'cors',
  'sec-fetch-site': 'cross-site',
};

export const adminScopes = [
  'admin:payments',
  'admin:payment-gateway',
  'admin:finance-accounts',
  'admin:payment-methods',
  'write:payment-gateway',
  'read:payment-methods',
  'read:finance-accounts',
  'write:finance-accounts',
  'update:finance-accounts',
  'delete:finance-accounts',
];

export default Config;

export const defaultErrorMessage =
  "We're sorry something just broke here. Please call us on 1300-274-448 to complete your payment. We apologise for this inconvenience.";

export const HTTP = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
};

const supportText = 'Please use another payment method, or contact our team on 1-300-274-448 for further support.';
export const alerts = {
  UNAUTHORIZED: 'Unauthorized. Please check your login credentials.',
  BAD_REQUEST: 'Some of the input details are not correct.  Please check and try again. ',
  UNPROCESSABLE_ENTITY: 'Card declined. Please check you have sufficient funds or use alternative payment option. ',
  CONFLICT:
    'Hi there, unfortunately the card details you have entered is already in use with another account. Please use another payment method, or contact our team on 1-300-274-448 for further support.',
  FAILED_PAYMENT: `Hi there, unfortunately we encountered an issue with processing your payment. ${supportText}`,
  NOT_FOUND: 'Not found',
  FORBIDDEN: `Hi there, unfortunately you dont have a permission to make a payment. ${supportText}`,
  FAILED_PAYMENT_UPDATE: 'Failed to update payment method',
  FAILED_IFRAME: 'Failed to fetch iframe url',
  INVALID_CARD_EXPIRY: 'Invalid card expiry date. Please check and try again.',
  FAILED_PROCESS_CARD_PAYMENT: 'Card payment not proceed, please refresh the page and try again later',
};
