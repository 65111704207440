import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { SENTRY_ERROR, ERROR } from '../config/errorConfig';
import { withResponsive } from '@brighte/brighte-one-native';
import CustomError from '../components/CustomError';
import Error from '../components/Error';

interface States {
  error: any;
  eventId: any;
}

interface Props {
  children: React.ReactNode;
  responsiveClass: string;
  error: CustomError;
  appData: Record<string, any>;
}

export class ErrorBoundary extends Component<Props, States> {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidUpdate(prevProps): void {
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    const eventId = this.setErrorSentry(error, errorInfo);
    this.setState({ error: error.message, eventId });
  }

  setErrorSentry = (error, errorInfo): string => {
    let eventId = '';
    const { appData } = this.props;
    delete appData.cpayDetails;

    const severityKey =
      error.name !== SENTRY_ERROR.SEVERITY.ERROR ? SENTRY_ERROR.LEVEL.ERROR : SENTRY_ERROR.LEVEL.FATAL;

    switch (error.message) {
      case ERROR.INVALID_URL.CODE:
        break;
      case ERROR.TIMEOUT.CODE:
        break;
      default:
        Sentry.withScope((scope): void => {
          scope.setLevel(Sentry.Severity[severityKey]);
          scope.setExtras({ errorInfo });
          scope.setContext('appData', appData);
          eventId = Sentry.captureException(error);
        });
        break;
    }

    return eventId;
  };
  render(): React.ReactElement | React.ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (error && error.displayMessage === undefined) {
      return <Error />;
    } else {
      return children;
    }
  }
}
const mapStateToProps = ({ appData }) => {
  return {
    error: appData.error,
    appData,
  };
};

export default connect(mapStateToProps, null)(withResponsive(ErrorBoundary));
