import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { withResponsive } from '@brighte/brighte-one-native';
import withTokenRefresh from '../../hoc/withTokenRefresh';
import Verification from './Verification';
import { getAuthService } from '../../AuthService';
import { paramsFromURL } from '../../utilities';
import { adminLoginRequest } from '../../actions';

const Admin = () => {
  const { identity, initData } = useSelector((state: Record<string, any>) => state.appData);
  const { code } =  paramsFromURL();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(code)) {
      getAuthService().login();
    } else {
      dispatch(adminLoginRequest())
    }
  }, [code, dispatch])

  if (!isEmpty(identity)) {
    return <Verification {...initData} />
  }

  return !isEmpty(code) ? (
    <p>Verifying ...</p>
  ) : (
    <p>Redirecting ...</p>
  );
};

export default withResponsive(withTokenRefresh(Admin));
