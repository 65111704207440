import styled from 'styled-components';
import { theme } from '@brighte/brighte-one-native';
import { applyResponsiveness } from '@brighte/brighte-one-core/src/helpers';

const App = styled.div`
  align-self: center;
  flex-direction: column;
  margin: auto;
  min-height: 100%;
  width: ${({ responsiveClass }) => (applyResponsiveness(responsiveClass, 'SM') ? '100%' : '720px')};
  display: flex;
`;

const Container = styled.div`
  z-index: 1;
  background-color: ${theme.WhiteColor};
  padding: ${({ responsiveClass }) => (applyResponsiveness(responsiveClass, 'SM') ? '20px' : ' 40px')};
  ${props => props.customStyle}
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 42px;
  ${props => props.customStyle}
`;

App.Container = Container;
App.LogoWrapper = LogoWrapper;

App.defaultProps = {
  theme: {
    BrighteGreen: theme.BrighteGreen,
    AlertRed: theme.AlertRed,
    LightBackground: theme.LightBackground,
  },
};

export default App;
