import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v1 as uuid } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import {
  Col,
  TextInputContainer,
  ButtonListContainer,
  Modal,
  Heading,
  Text,
  SelectionContainer,
  Row,
  Section,
  withResponsive,
  Alert,
  theme,
} from '@brighte/brighte-one-native';
import { actions } from '@brighte/brighte-one-core';
import CardAdded from '../styles/elemets/cardAddedCell';
import StyledTextInput from '../styles/elemets/styledTextInput';
import {
  StyledTextGray,
  StyledClientText,
  InlineContainer,
  StyledTextContainer,
  DottedLine,
} from '../styles/elemets/styledTexts';
import {
  getIframeUrl,
  savePaymentMethods,
  getPaymentDetails,
  setTransactionReference,
  setCpayDetails,
  setAlertMessage,
  updatePaymentMethods,
  getPaymentMethods,
  getAccountDetailsSuccess,
} from '../actions';
import { listCpayMethods } from '../saga/api';
import { OptionItem, searchPayMethodByToken, isValidJson, getUserId, numberValidation } from '../utilities';

import config, { HTTP, alerts } from '../config';
import moment from 'moment';

import { PaymentMethodListItem, PaymentMethodListNewButton } from '../components/PaymentMethod';
import { useAccountDetails } from '../graphql/queries/useAccountDetails';
import { PaymentCardType, PaymentMethodType } from '../config/types';

interface StoreFields {
  paymentMethods: Array<Record<string, any>>;
  accountDetails: Record<string, any>;
  iframeUrl: string | undefined;
  transactionReference: string | undefined;
  cpayDetails: any;
  cpayMethodUrl: string | null;
  dateList: Array<OptionItem> | [];
  paymentMethod: string | undefined;
  paymentType: any;
  initData: any;
  identity: Record<string, any>;
  needUpdateCpayMethod: boolean;
  alertMessage: string | null;
}

type PaymentOption = { value: string; renderer: any };

export const calculateRepaymentAmount = (amount: number, financialProduct: string, weeklyAccountKeepingFee: number) => {
  return financialProduct === 'GreenLoan' ? amount : amount + weeklyAccountKeepingFee * 2;
};

const paymentMethodToEnumMap = {
  card: PaymentMethodType.CREDIT_CARD,
  debit: PaymentMethodType.DIRECT_DEBIT,
};

export const validateAmount = (amount: string, max: number) => {
  let error = '';
  if (!amount.match(numberValidation) || isEmpty(amount)) {
    error = 'Amount entered is invalid. Please try again.';
  } else if (parseFloat(amount) > max) {
    error = 'Oops! You are trying to repay more than you owe.';
  }

  return {
    isValid: isEmpty(error),
    error,
  };
};

export const numberWithCommas = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const Payment = props => {
  const { responsiveClass } = props;
  const { responsiveBreakPoint, formId } = config;
  const dispatch = useDispatch();
  const {
    accountDetails,
    iframeUrl,
    initData,
    dateList,
    transactionReference,
    cpayDetails,
    paymentMethod,
    paymentType,
    cpayMethodUrl,
    identity,
    needUpdateCpayMethod,
    paymentMethods,
    alertMessage,
  } = useSelector((state: any): StoreFields => {
    const {
      appData: {
        accountDetails,
        iframeUrl,
        initData,
        dateList,
        transactionReference,
        cpayDetails,
        cpayMethodUrl,
        needUpdateCpayMethod,
        identity,
        paymentMethods,
        alertMessage,
      },
      formData: {
        [formId]: {
          fields: { paymentType = {}, paymentMethod = {} },
        },
      },
    } = state;
    return {
      accountDetails,
      iframeUrl,
      initData,
      dateList: accountDetails?.financialProduct.fpBranch === 'ASH' ? dateList.slice(1) : dateList, // Remove 'today' for ACT accounts
      transactionReference,
      cpayDetails,
      cpayMethodUrl,
      identity,
      needUpdateCpayMethod,
      paymentMethods,
      alertMessage,
      paymentMethod: paymentMethod?.value,
      paymentType: paymentType?.value,
    };
  });

  const [isVisible, setVisible] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[] | null>(null);
  const { applicationId } = useSelector(({ appData }) => appData.initData);

  const { loadAccountDetails } = useAccountDetails(applicationId, data =>
    dispatch(getAccountDetailsSuccess(data.financeAccount)),
  );

  const isCpaySelected = paymentType === 'CC';
  /* Be careful about mixing up admin user (manila agent) id AND application client user id (consumer). */

  const { balanceOwing = '0' } = initData;
  const userId = getUserId(initData, identity);
  const { accessToken } = identity;

  const cpayMessageHandler = async (event: MessageEvent): Promise<void> => {
    const { data } = event;

    if (!isEmpty(data) && isValidJson(data)) {
      const msgJson = JSON.parse(data);
      const { responseText, token, cardExpiry } = msgJson;

      if (!token && !cardExpiry) {
        dispatch(setAlertMessage(alerts.INVALID_CARD_EXPIRY));
        return;
      }

      try {
        // list payment methods, update payment method if already exists; Otherwise save payment method
        const response = await listCpayMethods({ accessToken, userId });
        const { status, ok } = response;

        if (!ok) {
          if (status === HTTP.UNAUTHORIZED) {
            dispatch(setAlertMessage(alerts.UNAUTHORIZED));
            return;
          } else {
            throw new Error('Unable to add new payment method, please refresh the page and try again later.');
          }
        }

        const existCpay = searchPayMethodByToken(await response.json(), token);

        if (existCpay) {
          dispatch(setCpayDetails({ ...existCpay, cardExpiry }));
        } else if (responseText && token && responseText === 'SUCCESS') {
          dispatch(savePaymentMethods(msgJson));
          setVisible(false);
        }
        // reset payment options as button list does not update options correctly
        // payment options will be re-populated using the updated payment methods in next render
        setPaymentOptions(null);
      } catch (error) {
        onCloseCpayWindow();
        dispatch(setAlertMessage(alerts.FAILED_PAYMENT));
        return;
      }
    }
  };

  const resetCpayButtonValue = (): void => {
    if (isEmpty(cpayDetails)) {
      dispatch(actions.setFieldAttributes('paymentType', formId, { value: '' }));
    }
  };

  const onCpayMethodClick = (paytype: string): void => {
    // allow to retry adding a CC
    if (paytype === 'CC' && isEmpty(cpayDetails)) {
      dispatch(setAlertMessage(null));
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const onPaymentMethodClick = (payMethod: string): void => {
    if (payMethod !== 'NEW') {
      dispatch(actions.setFieldAttributes('paymentType', formId, { value: '' }));
    }
  };

  const onCloseCpayWindow = (): void => {
    resetCpayButtonValue();
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener('message', cpayMessageHandler);
    return () => window.removeEventListener('message', cpayMessageHandler);
  });

  useEffect(() => {
    loadAccountDetails();
  }, [loadAccountDetails]);

  useEffect(() => {
    !paymentMethods && dispatch(getPaymentMethods());
  }, [paymentMethods, dispatch]);

  useEffect(() => {
    !iframeUrl && dispatch(getIframeUrl());
  }, [iframeUrl, dispatch]);

  useEffect(() => {
    !transactionReference && dispatch(setTransactionReference(uuid()));
  }, [transactionReference, dispatch]);

  useEffect(() => {
    !isEmpty(alertMessage) && onCloseCpayWindow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertMessage]);

  useEffect(() => {
    isEmpty(cpayDetails) && !isEmpty(cpayMethodUrl) && dispatch(getPaymentDetails());
    !isEmpty(cpayDetails) && setVisible(false);
  }, [cpayDetails, cpayMethodUrl, dispatch]);

  useEffect(() => {
    if (needUpdateCpayMethod) {
      dispatch(updatePaymentMethods());
    }
  }, [needUpdateCpayMethod, dispatch]);

  useEffect(() => {
    // refresh payment options to include custom payment options
    if (paymentMethods && accountDetails) {
      let customPaymentOptions = [];

      if (paymentMethods && paymentMethods.length) {
        customPaymentOptions = paymentMethods.map(method => ({
          value: method.id,
          renderer: PaymentMethodListItem({
            ...method,
            type: paymentMethodToEnumMap[method.type],
          }),
        }));
      }
      const accountDetailsPaymentOption = accountDetails.paymentMethod
        ? [
            {
              value: 'EP',
              renderer: PaymentMethodListItem({
                type:
                  accountDetails.paymentMethod.paymentMethod === PaymentMethodType.CREDIT_CARD
                    ? PaymentMethodType.CREDIT_CARD
                    : PaymentMethodType.DIRECT_DEBIT,
                cardType: PaymentCardType.VISA,
                cardExpiry: accountDetails.paymentMethod.creditCardExpiry,
                cardNumber: accountDetails.paymentMethod.maskedCreditCardInfo,
                accountBsb: String(accountDetails.paymentMethod.bankBsb),
                accountLast4: accountDetails.paymentMethod.maskedBankAccountNumber,
              }),
            },
          ]
        : [];

      setPaymentOptions([
        ...accountDetailsPaymentOption,
        ...customPaymentOptions,
        {
          value: 'NEW',
          renderer: PaymentMethodListNewButton(),
        },
      ]);
    }
  }, [accountDetails, paymentMethods, alertMessage]);

  // select the new card after it was added successfully
  useEffect(() => {
    if (paymentOptions && !paymentMethod && !isEmpty(cpayDetails)) {
      const selectedPaymentOption = paymentOptions.filter(payoption => payoption.value === cpayDetails.id);
      if (selectedPaymentOption?.[0]?.value) {
        dispatch(actions.setFieldAttributes('paymentMethod', formId, { value: selectedPaymentOption[0].value }));
      }
    }
  }, [cpayDetails, paymentOptions, paymentMethod, formId, dispatch]);

  if (!accountDetails) {
    return <></>;
  }

  return (
    <>
      <Modal
        id="cc-details"
        visible={isVisible}
        displayOverlay
        title="Credit Card Details"
        contentWidth={800}
        childrenContentWidth="100%"
        onClose={onCloseCpayWindow}
        boxStyle={{
          width: responsiveClass === responsiveBreakPoint ? '100%' : 500,
          height: 600,
        }}
      >
        <iframe
          id="iframe-cc"
          title="Credit Card"
          src={iframeUrl}
          width={responsiveClass === responsiveBreakPoint ? '90%' : 450}
          height={400}
          frameBorder={0}
          allowFullScreen
          aria-hidden={false}
          tabIndex={0}
        />
      </Modal>
      {accountDetails && (
        <Row id="row-0">
          <Col>
            <Heading text={'Account details'} level={2} />
            <InlineContainer>
              <StyledTextContainer customStyle={{ width: '55%', minWidth: 'fit-content' }}>
                <StyledClientText>Remaining Balance</StyledClientText>
              </StyledTextContainer>
              <DottedLine></DottedLine>
              <StyledTextContainer customStyle={{ width: 'auto', textAlign: 'right' }}>
                <StyledClientText customStyle={{ fontWeight: 'bold' }}>
                  ${numberWithCommas(parseFloat(accountDetails.balance).toFixed(2))}
                </StyledClientText>
              </StyledTextContainer>
            </InlineContainer>
            <InlineContainer>
              <StyledTextContainer customStyle={{ width: '77%', minWidth: 'fit-content' }}>
                <StyledClientText>Next Scheduled Payment</StyledClientText>
              </StyledTextContainer>
              <DottedLine></DottedLine>
              <StyledTextContainer customStyle={{ width: '37%', minWidth: 'fit-content', textAlign: 'right' }}>
                <StyledClientText customStyle={{ fontWeight: 'bold' }}>
                  {moment(accountDetails.nextPayment).format('DD-MMM-YYYY')}
                </StyledClientText>
              </StyledTextContainer>
            </InlineContainer>
            <InlineContainer>
              <StyledTextContainer customStyle={{ width: '67%', minWidth: 'fit-content' }}>
                <StyledClientText>
                  Next Repayment Amount{' '}
                  <StyledClientText
                    customStyle={{
                      fontSize: responsiveClass === responsiveBreakPoint ? '11px' : 'small',
                      display: responsiveClass === responsiveBreakPoint ? 'block' : 'inline',
                    }}
                  >
                    (including Weekly Account Keeping Fee)
                  </StyledClientText>
                </StyledClientText>
              </StyledTextContainer>
              <DottedLine></DottedLine>
              <StyledTextContainer customStyle={{ width: 'auto', textAlign: 'right' }}>
                <StyledClientText customStyle={{ fontWeight: 'bold' }}>
                  $
                  {numberWithCommas(
                    calculateRepaymentAmount(
                      parseFloat(accountDetails.repaymentAmount),
                      accountDetails.financialProduct.slug,
                      parseFloat(accountDetails.productConfiguration.weeklyAccountFee),
                    ).toFixed(2),
                  )}
                </StyledClientText>
              </StyledTextContainer>
            </InlineContainer>
          </Col>
        </Row>
      )}
      <Row id="row-1" customStyle={{ marginBottom: 40 }}>
        <Col id="col-1">
          <Heading text={`How much would you like to pay?`} level={2} />
          <Text customStyle={{ marginTop: 5 }}>Please enter the value of one-off payment you'd like to make.</Text>
        </Col>
      </Row>
      <div style={{ zIndex: 1 }}>
        <Row id="row-2">
          <Col id="col-2" inputGroup>
            <StyledTextInput
              id="pay-amount"
              initValue={balanceOwing}
              prefix="$"
              fieldName="amount"
              label="Enter an amount"
              validation={value => validateAmount(value, parseFloat(accountDetails.balance))}
              flex={3}
            />
            <SelectionContainer
              id="debit-date"
              flex={2}
              fieldName="debitDate"
              label="When should we debit?"
              items={dateList}
              initValue={[dateList[0].value]}
            />
          </Col>
        </Row>
      </div>
      {paymentOptions ? (
        <Row id="row-3.5">
          <Col id="col-3.5">
            <ButtonListContainer
              id="pay-options"
              label="Select a payment method"
              fieldName="paymentMethod"
              responsiveBreakPoint="XL"
              validationMessage="Please select a payment method."
              onPress={onPaymentMethodClick}
              options={paymentOptions}
            />
          </Col>
        </Row>
      ) : null}
      <Section watchVisibleFields={['paymentType']} visibilityLogic={() => paymentMethod === 'NEW'}>
        <Row id="row-4" customStyle={isCpaySelected && { margin: 0 }}>
          <Col id="col-4">
            <ButtonListContainer
              id="pay-options"
              label="Add a payment method"
              fieldName="paymentType"
              validationMessage="Please select a payment method type."
              onPress={onCpayMethodClick}
              options={[
                { value: 'DD', label: 'Bank account' },
                { value: 'CC', label: 'Debit/Credit Card' },
              ]}
              validation={(value: string) => {
                let isValid = true;
                if (!value || (!isEmpty(cpayDetails) && value === 'CC')) {
                  isValid = false;
                }
                return {
                  isValid,
                  error: !isValid ? 'Please choose a payment method.' : null,
                };
              }}
            />
            {!isEmpty(cpayDetails) && isCpaySelected && (
              <CardAdded>
                <CardAdded.CardAddedText>
                  You can only add one debit/credit card at a time. Please select your payment method above and{' '}
                  <strong>click continue</strong> if you wish to process your payment.
                </CardAdded.CardAddedText>
              </CardAdded>
            )}
          </Col>
        </Row>
      </Section>
      <Section
        watchVisibleFields={['accountName', 'accountBsb', 'accountNumber']}
        visibilityLogic={() => paymentType === 'DD'}
      >
        <Row id="row-5">
          <Col id="col-5" inputGroup>
            <TextInputContainer id="account-name" fieldName="accountName" label="Account name" />
          </Col>
        </Row>
        <Row id="row-6">
          <Col id="col-6" inputGroup>
            <TextInputContainer
              id="account-bsb"
              flex={2}
              fieldName="accountBsb"
              label="BSB"
              mask="###-###"
              validation={/^\d{6}$/}
              validationMessage="Please enter a valid BSB."
            />
            <TextInputContainer
              id="account-number"
              flex={5}
              fieldName="accountNumber"
              label="Account number"
              mask="### ### ###"
            />
          </Col>
        </Row>
      </Section>
      <Alert
        type="warning"
        backGround
        customStyle={{
          iconCustomStyle: { alignSelf: 'baseline', color: theme.BrighteBlue },
          customStyleContainer: { backgroundColor: theme.LightBackground, border: 'none' },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text customStyle={{ fontWeight: 600 }}>Important to know</Text>
          <Text>It can take up to 4 days for your Brighte account to reflect a one off payment.</Text>
        </div>
      </Alert>

      <Row id="row-7">
        <Col id="col-7">
          <StyledTextGray>
            By clicking "Continue", you authorise Brighte (User ID 502055/502056) through its own financial institution,
            to debit to your nominated account any amount Brighte has deemed payable by you.
          </StyledTextGray>
        </Col>
      </Row>
    </>
  );
};

Payment.defaultProps = {};

export default withResponsive(Payment);
