import get from 'lodash/get';

export const numberValidation = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;

export const paramsFromURL = (urlString: string | null = null): Record<string, string> => {
  const vars = urlString ? urlString.split('&') : window.location.search.split('&');
  const queryString = {};

  if (vars.join().trim()) {
    for (let i = 0; i < vars.length; i++) {
      const stringArr = vars[i].split('=');
      const pair = stringArr.map(item => item.replace('?', ''));
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);

      // If first entry with this name
      if (typeof queryString[key] === 'undefined') {
        queryString[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof queryString[key] === 'string') {
        const arr = [queryString[key], decodeURIComponent(value)];
        queryString[key] = arr;
        // If third or later entry with this name
      } else {
        queryString[key].push(decodeURIComponent(value));
      }
    }
  }

  return queryString;
};

export const decodeQueryParams = (queryObject): Record<string, string> => {
  let decodedQueryObject = {};
  for (let [key] of Object.entries(queryObject)) {
    decodedQueryObject = paramsFromURL(decodeB64(key));
  }

  return decodedQueryObject;
};

export const monthInText = [
  { full: 'January', short: 'Jan' },
  { full: 'February', short: 'Feb' },
  { full: 'March', short: 'Mar' },
  { full: 'April', short: 'Apr' },
  { full: 'May', short: 'May' },
  { full: 'June', short: 'Jun' },
  { full: 'July', short: 'Jul' },
  { full: 'August', short: 'Aug' },
  { full: 'September', short: 'Sep' },
  { full: 'October', short: 'Oct' },
  { full: 'November', short: 'Nov' },
  { full: 'December', short: 'Dec' },
];

export interface OptionItem {
  label: string;
  value: string | number;
}

export const debitDateOptions = (days: number = 7): Array<OptionItem> => {
  let dateOptions: Array<OptionItem> = [];
  const today = new Date();

  for (let index = 0; index < days; index++) {
    const current = dateOptions.length;
    if (current === 0) {
      dateOptions[current] = {
        label: 'Today',
        value: 'today',
      };
    } else if (current === 1) {
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      dateOptions[current] = {
        label: 'Tomorrow',
        value: tomorrow.getTime(),
      };
    } else {
      const date = new Date();
      date.setDate(today.getDate() + current);
      dateOptions[current] = {
        label: `In ${current} days (${date.getDate()}-${monthInText[date.getMonth()].short})`,
        value: date.getTime(),
      };
    }
  }
  return dateOptions;
};

export const searchPayMethodByToken = (
  payMethods: Record<string, string>[] | [] | null,
  tokenP: string,
): Record<string, string> | false => {
  if (payMethods && payMethods.length) {
    let pMethod: false | Record<string, string> = false;
    payMethods.forEach(element => {
      const { token } = element;
      if (token === tokenP) {
        pMethod = element;
      }
    });

    return pMethod;
  }

  return false;
};

export const decodeB64 = (code: string): string => {
  try {
    return window.atob(code);
  } catch (error) {
    return '';
  }
};

export const encodeB64 = (val: string): string => {
  try {
    return window.btoa(val);
  } catch (error) {
    return '';
  }
};

export const cleanSessionLater = (milliseconds: number = 2000): void => {
  setTimeout(() => window.sessionStorage.clear(), milliseconds); // TODO find a better way to clean store
};

export const cleanStoreLater = (milliseconds: number = 100): void => {
  setTimeout(() => {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }, milliseconds);
};

export const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getUserId = (initData: Record<string, any>, identity: Record<string, any>): number => {
  const { portalUserId, isAdmin } = initData;

  if (isAdmin === '1') {
    return parseInt(portalUserId);
  }

  return get(identity, 'user.id', 0);
};
