import React, { ReactElement } from 'react';
import { BankAccount, CreditCard, PaymentCardType, PaymentMethodType } from '../config/types';
import { InlineContainer, StyledPaymentMethodText, StyledTextContainer } from '../styles/elemets/styledTexts';
import masterCardLogo from '../assets/icons/mastercard-logo.png';
import visaLogo from '../assets/icons/visa-logo.png';
import plusSign from '../assets/icons/plus-sign.svg';
import { Icons, theme } from '@brighte/brighte-one-native';

const getCardNumberFormat = (cardNumber: string) =>
  'XX-' + cardNumber.substring(cardNumber.length - 4, cardNumber.length);

const getCardExpiryFormat = (cardExpiry: string) => {
  const cardExpiryArr = cardExpiry.split('/');
  const month = cardExpiryArr[0];
  const year = cardExpiryArr[1].substring(cardExpiryArr[1].length - 2, cardExpiryArr[1].length);
  return `Expires ${month}-${year}`;
};

const getCardNumberAndExpiryFormat = (cardNumber, cardExpiry) => {
  let result = '';
  if (cardNumber && cardExpiry) {
    result = `${getCardNumberFormat(cardNumber)} / ${getCardExpiryFormat(cardExpiry)}`;
  }

  return result;
};

const getBsbFormat = (bsb: string) => bsb ? `${bsb?.substring(0, 3)}-${bsb?.substring(bsb?.length - 3, bsb?.length)}` : '???';

const getAccountNumberFormat = (accountNumber: string) =>
accountNumber ? `XXXX-${accountNumber?.substring(accountNumber?.length - 4, accountNumber?.length)}` : 'XXXX-????';

const getAccountNumberAndBsbFormat = (accountNumber: string, bsb: string) =>
  `${getAccountNumberFormat(accountNumber)} / ${getBsbFormat(bsb)}`;

const BankAccountItem = (props: { method: BankAccount; isSelected: boolean }) => {
  const { method, isSelected } = props;
  const { accountBsb, accountLast4 } = method;
  return (
    <InlineContainer>
      <div style={{ paddingLeft: '10px', width: '15%' }}>
        <Icons.DirectDebit size={50} />
      </div>
      <StyledTextContainer customStyle={{ minWidth: 'fit-content' }}>
        <StyledPaymentMethodText customStyle={{ color: isSelected ? theme.WhiteColor : theme.DarkText }}>
          Bank Account {getAccountNumberAndBsbFormat(accountLast4, accountBsb)}
        </StyledPaymentMethodText>
      </StyledTextContainer>
    </InlineContainer>
  );
};

const CreditCardItem = (props: { method: CreditCard; isSelected: boolean }) => {
  const { method, isSelected } = props;
  const { cardNumber, cardExpiry, cardType } = method;
  return (
    <InlineContainer>
      <div style={{ paddingLeft: '10px', width: '15%' }}>
        {cardType === PaymentCardType.MasterCard && (
          <img src={masterCardLogo} alt="mastercard-logo" style={{ width: '60px', paddingTop: '10px' }}></img>
        )}
        {cardType === PaymentCardType.VISA && (
          <img src={visaLogo} alt="visa-logo" style={{ width: '60px', paddingTop: '10px' }}></img>
        )}
      </div>
      <StyledTextContainer customStyle={{ minWidth: 'fit-content' }}>
        <StyledPaymentMethodText customStyle={{ color: isSelected ? theme.WhiteColor : theme.DarkText }}>
          {cardType} {getCardNumberAndExpiryFormat(cardNumber, cardExpiry)}
        </StyledPaymentMethodText>
      </StyledTextContainer>
    </InlineContainer>
  );
};

export const PaymentMethodComponent = (props: { method: BankAccount | CreditCard; isSelected: boolean }) => {
  const { method, isSelected } = props;
  const { type } = method;
  return type === PaymentMethodType.CREDIT_CARD ? (
    <CreditCardItem isSelected={isSelected} method={method as CreditCard}></CreditCardItem>
  ) : (
    <BankAccountItem isSelected={isSelected} method={method as BankAccount}></BankAccountItem>
  );
};

export const PaymentMethodListItem = (method: BankAccount | CreditCard) => (isSelected: boolean) => {
  return <PaymentMethodComponent method={method} isSelected={isSelected} />;
};

export const PaymentMethodListNewButton =
  () =>
  (isSelected: boolean): ReactElement =>
    (
      <InlineContainer>
        <div style={{ paddingLeft: '10px', width: '15%' }}>
          <img src={plusSign} alt="plus-sign" style={{ width: '30px', paddingTop: '10px', paddingLeft: '15px' }}></img>
        </div>
        <StyledTextContainer customStyle={{ minWidth: 'fit-content' }}>
          <StyledPaymentMethodText customStyle={{ color: isSelected ? theme.WhiteColor : 'rgb(148, 154, 166)' }}>
            Add new payment method
          </StyledPaymentMethodText>
        </StyledTextContainer>
      </InlineContainer>
    );
