import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './styles/global';
import Store from './store';
import * as serviceWorker from './serviceWorker';

if (isBrowserSupport()) {
  ReactDOM.render(
    <Store>
      <div>
        <GlobalStyle />
        <App />
      </div>
    </Store>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
