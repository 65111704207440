import React from 'react';
import { Text, theme } from '@brighte/brighte-one-native';
import styled from 'styled-components';

const CardAdded = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
  align-self: center;
  align-items: center;
  justify-content: center;
  ${props => props.customStyle}
`;

const CardAddedText = props => (
  <Text customStyle={{ color: theme.MediumText, marginLeft: 10, paddingTop: 3 }} {...props} />
);

CardAdded.CardAddedText = CardAddedText;

export default CardAdded;
