import React, { ComponentType, useCallback, useEffect } from 'react';
import { actions } from '@brighte/brighte-one-core';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import Config from '../config';
import { isIdentity } from '../config/types';
import { cleanStoreLater } from '../utilities';
import { loadState } from '../utilities/localStorage';
import { persistIdentity, setAlertMessage } from '../actions';
import { refreshToken } from '../saga/api';

const withTokenRefresh = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithTokenRefresh = (props: any) => {
    const { identity = {}, needRefresh = false } = useSelector((state: Record<string, any>) => state.appData);
    const { setValidatePage } = actions;
    const dispatch = useDispatch();
    const localIdentity = loadState(Config.formId);

    useEffect(() => {
      const refreshTokenAction = async (): Promise<void> => {
        if (!isEmpty(identity) && needRefresh) {
          try {
            const newIdentity = await refreshToken(get(identity, 'refreshToken', '')).then((response: Response) =>
              response.json(),
            );

            if (isIdentity(newIdentity)) {
              dispatch(persistIdentity({ identity: newIdentity, needRefresh: false }));
            } else if (newIdentity.hasOwnProperty('message')) {
              throw new Error(newIdentity.message);
            } else {
              throw new Error('User identity not valid');
            }
          } catch (error) {
            console.warn('Error on retrieve identity.', error);
            dispatch(persistIdentity({ identity: {}, needRefresh: false }));
            dispatch(setAlertMessage('Session expired, please re-login.'));
            cleanStoreLater();
          }
        }
      };
      refreshTokenAction();
    }, [dispatch, identity, needRefresh, setValidatePage]);

    const fetchIdentity = useCallback((): void => {
      if (isEmpty(identity) && !isEmpty(localIdentity)) {
        dispatch(persistIdentity({ identity: localIdentity, needRefresh: true }));
      }
    }, [dispatch, identity, localIdentity]);

    return <WrappedComponent fetchLocalIdentity={fetchIdentity} localIdentity={localIdentity} {...props} />;
  };

  return WithTokenRefresh;
};

export default withTokenRefresh;
