import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  GET_IFRAME_URL,
  SAVE_PAYMENT_METHODS,
  GET_PAYMENT_DETAILS,
  UPDATE_PAYMENT_METHODS,
  SAVE_PAYMENT_METHODS_SUCCESS,
  LIST_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_SUCCESS,
} from '../actions/types';
import { getUserId } from '../utilities';
import config, { HTTP, alerts } from './../config';

import {
  getIframeUrlSuccess,
  savePaymentMethodsSuccess,
  getPaymentDetailsSuccess,
  updatePaymentMethodsSuccess,
  setAlertMessage,
  setErrorResponse,
  listPaymentMethodsSuccess,
} from '../actions';
import { getCreditCardUrl, savePaymentMethod, getCpayDetails, updatePaymentMethods, listCpayMethods } from './api';
import { fetchSaga } from './fetchSaga';
import { actions } from '@brighte/brighte-one-core';

const defaultActions = { eError: setErrorResponse, eAlert: setAlertMessage };

const IframeUrlActions = {
  ...defaultActions,
  eResponse: getIframeUrlSuccess,
};

const SavePaymentMethodsActions = {
  ...defaultActions,
  eResponse: savePaymentMethodsSuccess,
};

const GetPaymentIdActions = {
  ...defaultActions,
  eResponse: getPaymentDetailsSuccess,
};

const UpdatePaymentMethodsActions = {
  ...defaultActions,
  eResponse: updatePaymentMethodsSuccess,
};

export const apiIframeUrl = fetchSaga(
  IframeUrlActions,
  function* (): any {
    return yield call(getCreditCardUrl);
  },
  [{ code: HTTP.OK, event: 'success' }],
);

export const apiSavePaymentMethods = fetchSaga(
  SavePaymentMethodsActions,
  function* (payload): any {
    const { token, cardHolder, cardNumber, cardExpiry, cardType } = payload;
    const { identity, initData } = yield select(({ appData: { identity, initData } }) => ({ identity, initData }));

    const userId = getUserId(initData, identity);
    const type = 'card';
    const data = {
      accessToken: identity.accessToken,
      userId,
      type,
      token,
      cardHolder,
      cardNumber,
      cardExpiry,
      cardType,
      source: config.appType,
    };

    const response = yield call(savePaymentMethod, data);
    const { ok } = response;

    if (ok) {
      const location = response.headers.get('location');
      const { id } = yield response.json();
      yield put(actions.setFieldAttributes('paymentMethod', 'brighteOneOffPayment', { value: id }));
      if (!location) {
        throw new Error('Credit Card payment not proceed!');
      } else {
        return yield { url: location };
      }
    }

    return response;
  },
  [{ code: HTTP.CREATED, event: 'success' }],
);

export const apiListPaymentMethods = fetchSaga(
  {
    eAlert: setAlertMessage,
    eError: setErrorResponse,
    eResponse: listPaymentMethodsSuccess,
  },
  function* (): any {
    const {
      identity,
      initData,
      identity: { accessToken },
    } = yield select(({ appData }) => appData);
    const userId = getUserId(initData, identity);

    return yield call(listCpayMethods, { accessToken, userId });
  },
);

export const apiGetPaymentDetails = fetchSaga(GetPaymentIdActions, function* (): any {
  const {
    identity,
    initData,
    identity: { accessToken },
    cpayMethodUrl,
  } = yield select(({ appData }) => appData);
  const userId = getUserId(initData, identity);

  return yield call(getCpayDetails, { accessToken, cpayMethodUrl, userId });
});

export const apiUpdatePaymentMethods = fetchSaga(
  UpdatePaymentMethodsActions,
  function* (): any {
    const {
      identity,
      initData,
      identity: { accessToken },
      cpayDetails: { id, cardExpiry },
    } = yield select(({ appData }) => appData);
    const userId = getUserId(initData, identity);

    return yield call(updatePaymentMethods, { accessToken, id, cardExpiry, userId });
  },
  [
    { code: HTTP.NO_CONTENT, event: 'success' },
    { code: HTTP.NOT_FOUND, event: 'alert', message: `Payment method - ${alerts.NOT_FOUND}` },
  ],
);

export function* requestIframeUrl(): Generator<any> {
  yield call(apiIframeUrl);
}

export function* requestSavePaymentMethods(payload: Record<string, any>): Generator<any> {
  yield call(apiSavePaymentMethods, payload);
}

export function* requestListPaymentMethods(): Generator<any> {
  yield put(actions.setFieldAttributes('paymentMethod', 'brighteOneOffPayment', { value: '' }));
  yield put(actions.setFieldAttributes('paymentType', 'brighteOneOffPayment', { value: '' }));
  yield call(apiListPaymentMethods);
}

export function* requestGetPaymentDetails(): Generator<any> {
  yield call(apiGetPaymentDetails);
}

export function* requestUpdatePaymentMethods(): Generator<any> {
  yield call(apiUpdatePaymentMethods);
}

function* watchPaymentActions(): Generator<any> {
  yield takeLatest(GET_IFRAME_URL, requestIframeUrl);
  yield takeLatest(LIST_PAYMENT_METHODS, requestListPaymentMethods);
  yield takeLatest(UPDATE_PAYMENT_METHODS_SUCCESS, requestListPaymentMethods);
  yield takeLatest(SAVE_PAYMENT_METHODS_SUCCESS, requestListPaymentMethods);
  yield takeLatest(SAVE_PAYMENT_METHODS, requestSavePaymentMethods);
  yield takeLatest(GET_PAYMENT_DETAILS, requestGetPaymentDetails);
  yield takeLatest(UPDATE_PAYMENT_METHODS, requestUpdatePaymentMethods);
}

export default watchPaymentActions;
