import { ApolloClient, InMemoryCache } from '@apollo/client';
import Config from '../config';

export function createFinanceCoreClient(accessToken) {
  const financeCoreClient = new ApolloClient({
    uri: `${Config.financeCoreUrl()}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return financeCoreClient;
}
