import React from 'react';
import { Text, theme } from '@brighte/brighte-one-native';
import styled from 'styled-components';

export const StyledText = props => (
  <Text customStyle={{ textAlign: 'center', marginTop: 10, lineHeight: 25 }} {...props} />
);

export const StyledTextGray = props => (
  <Text
    customStyle={{
      lineHeight: 20,
      marginTop: 10,
      color: theme.MediumText,
      fontSize: theme.labelFontSize,
    }}
    {...props}
  />
);

export const StyledPaymentMethodText = styled(Text)`
  font-size: 12px;
  ${({ customStyle }) => customStyle};
`;

export const StyledClientText = styled(Text)`
  width: 100%;
  color: white;
  ${({ customStyle }) => customStyle};
  {...props}
`;

export const InlineContainer = styled.div`
  display: inline-flex;
  width: 100%;
  ${({ customStyle }) => customStyle};
  {...props}
`;

export const StyledTextContainer = styled.div`
  padding: 10px;
  width: 100%;
  text-align: ${props => props.textAlign || 'left'};
  ${({ customStyle }) => customStyle};
  {...props}
`;

export const HorizontalLine = styled.hr`
  text-align: center;
  width: 100%;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: #edf5fb;
  border: 0 none;
  ${({ customStyle }) => customStyle};
  {...props}
`;

export const DottedLine = styled.hr`
padding: 11px;
width: 100%;
border: none;
border-bottom: 2px dotted #C2C2C2;
${({ customStyle }) => customStyle};
{...props}
`;
