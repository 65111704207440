import React, { ReactElement } from 'react';
import createSagaMiddleware from 'redux-saga';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createLogger } from 'redux-logger';
import Brighte, { persistDataMiddleware } from '@brighte/brighte-one-native';
import { init, captureException } from '@sentry/browser';
import persistLocalMiddleware from '../middleWare/persistLocal';
import config from '../config';
import rootSaga from '../saga';
import appData from '../reducer';

interface Props {
  children: ReactElement;
}

const Store = ({ children }: Props) => {
  if (['production', 'uat'].includes(process.env.NODE_ENV)) {
    init({
      dsn: config.sentryUrl,
      environment: process.env.NODE_ENV || 'development',
    });
  }

  const middleWares = [
    persistLocalMiddleware(config.formId, 'appData.identity', (state: Record<string, any>) =>
      get(state, 'appData.initData.isAdmin', '0') === '1' && !isEmpty(get(state, 'appData.identity', {}))
        ? true
        : false,
    ),
    persistDataMiddleware(config.formId),
    createSagaMiddleware({
      onError: err => captureException(err),
    }),
  ];

  process.env.NODE_ENV !== 'production' && middleWares.push(createLogger());

  return (
    <Brighte
      id={config.formId}
      customMiddleware={middleWares}
      configureMiddlewares={midlewares => midlewares[2].run(rootSaga)}
      customReducers={{ appData }}
    >
      {children}
    </Brighte>
  );
};

export default Store;
