import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Row,
  Col,
  Button,
  Navigation,
  Grid,
  FormContainer,
  Icons,
  Text,
  Label,
  theme,
  withResponsive,
  LoadingContainer,
  ProgressBarContainer,
  PageContainer,
} from '@brighte/brighte-one-native';
import { actions, Direction } from '@brighte/brighte-one-core';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { ScrollView } from 'react-native';
import { ApolloProvider } from '@apollo/client';

import { Page } from './config/types';
import { adminLoginRequest, setError } from './actions';
import ErrorBoundary from './pages/ErrorBoundary';
import SecureCodePage from './pages/SecureCode';
import Payment from './pages/Payment';
import Submit from './pages/Submit';
import Authenticate from './pages/Authenticate';
import config from './config';
import scroll from './utilities/scroll';
import StyledApp from './styles/blocks/App';
import CallUs from './styles/blocks/CallUs';
import Copyright from './styles/blocks/Copyright';
import './styles/css/fonts.css';
import { createFinanceCoreClient } from './graphql';

// @todo: fix up type for PageContainer
const PageC = PageContainer as any;

const App = ({ responsiveClass }) => {
  const {
    isLoading = true,
    alertMessage = null,
    identity,
    error,
    formSent,
    initData: { isAdmin, applicationId, userId, portalUserId } = {
      isAdmin: '0',
      applicationId: undefined,
      userId: undefined,
      portalUserId: undefined,
    },
  } = useSelector(state => state?.appData || {});
  const dispatch = useDispatch();

  const pages: Page[] = [
    { pageName: 'Secure Code', page: SecureCodePage },
    { pageName: 'Payment', page: Payment },
    { pageName: 'Submit', page: Submit },
  ];
  if (isAdmin === '1') {
    pages[0] = { pageName: 'Login', page: Authenticate };
  }

  const submitHandler = () => {
    dispatch(actions.validatePageFields(0, config.formId));
    if (isEmpty(identity) && isAdmin === '1') {
      dispatch(adminLoginRequest());
    }
  };

  useEffect(() => {
    // if key information missing, stop processing
    if (isEmpty(error) && formSent === false && (!applicationId || !userId || (isAdmin === '1' && !portalUserId))) {
      dispatch(setError({ error: 'Unauthorised', errorType: 'UNAUTHORIZED' })); // TODO need to have a proper error msg type
    }
  });

  const { accessToken } = useSelector(({ appData }) => appData.identity);

  const client = useMemo(() => createFinanceCoreClient(accessToken), [accessToken]);

  return (
    <ApolloProvider client={client}>
      <Grid>
        <StyledApp responsiveClass={responsiveClass}>
          <StyledApp.LogoWrapper>
            <Icons.BrighteLogoText size={50} color={theme.BrighteGreen} />
          </StyledApp.LogoWrapper>
          <ErrorBoundary>
            <FormContainer formName={config.formId}>
              <ScrollView style={{ borderRadius: 4, overflow: 'visible', zIndex: 1 }}>
                <ProgressBarContainer customStyle={{ customStyleProgress: { borderTopLeftRadius: 4 } }} />
                <StyledApp.Container responsiveClass={responsiveClass}>
                  <LoadingContainer size={50} visibilityLogic={store => get(store, 'appData.isLoading', false)}>
                    <PageC navigationConfig={pages} />
                    {alertMessage && !isLoading && <Alert type="warning" backGround text={alertMessage} />}
                  </LoadingContainer>
                </StyledApp.Container>
                <Row>
                  <Col>
                    <Navigation direction={Direction.NEXT} isLoading={isLoading}>
                      {(navigate, currentPage) =>
                        currentPage !== 3 && !isEmpty(identity) ? (
                          <Button
                            customStyle={{
                              customStyleButton: {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                              },
                            }}
                            onPress={() => {
                              navigate();
                              scroll();
                            }}
                            label={currentPage === 2 ? 'Submit' : 'Continue'}
                            disabled={isLoading}
                          />
                        ) : (
                          isAdmin === '1' &&
                          isEmpty(identity) && <Button disabled={isLoading} onPress={submitHandler} label="Login" customStyle={{}} />
                        )
                      }
                    </Navigation>
                  </Col>
                </Row>
              </ScrollView>

              <Row>
                <Col>
                  <CallUs>
                    <CallUs.IconCell>
                      <Icons.Phone size={24} color={theme.BrighteGreen} />
                    </CallUs.IconCell>
                    <CallUs.TitleCell>
                      <Text text={'Need help?'} customStyle={{ fontSize: 24 }} />
                    </CallUs.TitleCell>
                    <CallUs.TextCell>
                      <Text
                        customStyle={{
                          lineHeight: 20,
                          color: theme.MediumText,
                          fontSize: theme.labelFontSize,
                        }}
                      >
                        Call us on 1-300-274-448 if you have concerns regarding making payments to your Brighte account.
                      </Text>
                    </CallUs.TextCell>
                  </CallUs>
                  <Copyright>
                    <Copyright.TextCell>
                      <Label text="&copy; Copyright 2020. Brighte Capital Pty Ltd | 1300-274-448 (BRIGHTE)" />
                      <Label
                        text="ABN 74 609 165 906 | Australian credit licence number 508217"
                        customStyle={{ marginTop: 5 }}
                      />
                    </Copyright.TextCell>
                  </Copyright>
                </Col>
              </Row>
            </FormContainer>
          </ErrorBoundary>
        </StyledApp>
      </Grid>
    </ApolloProvider>
  );
};

export default withResponsive(App);
