import throttle from 'lodash/throttle';
import { saveState } from '../utilities/localStorage';

export default (
  id: string,
  key: string,
  condition?: (store: Record<string, any>) => boolean,
) => store => next => action => {
  switch (action.type) {
    default:
      next(action);
      throttle(() => {
        saveState(id, key, store.getState(), condition);
      }, 1000)();
      break;
  }
};
