export const ERROR = 'ERROR';
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const SET_CPAY_DETAILS = 'SET_CPAY_DETAILS';
export const SECURE_CODE_SUCCESS = 'SECURE_CODE_SUCCESS';
export const SET_TRANSACTION_REFERENCE = 'SET_TRANSACTION_REFERENCE';
export const LOADING = 'LOADING';
export const SECURE_CODE_FIRE = 'SECURE_CODE_FIRE';
export const GET_IFRAME_URL = 'GET_IFRAME_URL';
export const GET_IFRAME_URL_SUCCESS = 'GET_IFRAME_URL_SUCCESS';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_PRODUCT_CONFIG = 'GET_PRODUCT_CONFIG';
export const LIST_PAYMENT_METHODS = 'LIST_PAYMENT_METHODS';
export const LIST_PAYMENT_METHODS_SUCCESS = 'LIST_PAYMENT_METHODS_SUCCESS';
export const SAVE_BANK_ACCOUNT = 'SAVE_BANK_ACCOUNT';
export const SAVE_PAYMENT_METHODS = 'SAVE_PAYMENT_METHODS';
export const SAVE_PAYMENT_METHODS_SUCCESS = 'SAVE_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
export const UPDATE_PAYMENT_METHODS_SUCCESS = 'UPDATE_PAYMENT_METHODS_SUCCESS';
export const SUBMIT = 'SUBMIT';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const ALERT_MESSAGE = 'ALERT_MESSAGE';
export const SET_RESPONSE_ERROR = 'SET_RESPONSE_ERROR';
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const PERSIST_IDENTITY = 'PERSIST_IDENTITY';

interface SetError {
  type: typeof ERROR;
  errorType: string;
  error: string;
}

export type SetErrorType = SetError;
