import React from 'react';
import { Col, Row, withResponsive, Heading } from '@brighte/brighte-one-native';
import oops from '../assets/icons/oops.svg';
import ErrorBoundary from '../../src/styles/blocks/ErrorBoundary';
import { defaultErrorMessage } from '../config';

const Error = ({ responsiveClass, msg = defaultErrorMessage }) => {
  return (
    <ErrorBoundary responsiveClass={responsiveClass}>
      <Row id="error-row-1">
        <Col id="error-col-1">
          <Heading text="Oh Snap!" level={1} />
          <ErrorBoundary.Text>{msg}</ErrorBoundary.Text>
          <img src={oops} alt="something went wrong!" />
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default withResponsive(Error);
