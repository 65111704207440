import {
  ERROR,
  // SetErrorType,
  GET_IFRAME_URL,
  GET_IFRAME_URL_SUCCESS,
  SAVE_PAYMENT_METHODS,
  SAVE_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_SUCCESS,
  LOADING_START,
  LOADING_STOP,
  AUTHENTICATE,
  AUTHENTICATE_SUCCESS,
  SET_CPAY_DETAILS,
  SET_TRANSACTION_REFERENCE,
  SUBMIT,
  SUBMIT_SUCCESS,
  ALERT_MESSAGE as SET_ALERT_MESSAGE,
  SET_RESPONSE_ERROR,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  PERSIST_IDENTITY,
  GET_ACCOUNT_DETAILS_SUCCESS,
  LIST_PAYMENT_METHODS_SUCCESS,
} from '../actions/types';
import { cleanSessionLater, debitDateOptions, decodeQueryParams, paramsFromURL } from '../utilities';

export const initState = {
  accountDetails: null,
  isLoading: false,
  initData: decodeQueryParams(paramsFromURL()),
  iframeUrl: null,
  dateList: debitDateOptions(3),
  identity: {},
  needRefresh: false,
  cpayDetails: {},
  cpayMethodUrl: null,
  transactionReference: null,
  needUpdateCpayMethod: false,
  error: null,
  alertMessage: null,
  formSent: false,
};

export default (state: Record<string, any> = initState, action) => {
  switch (action.type) {
    case ERROR:
      cleanSessionLater();
      return { ...state, isLoading: false, error: action.error };
    case LOADING_START: {
      return { ...state, isLoading: true };
    }
    case LOADING_STOP: {
      return { ...state, isLoading: false };
    }
    case AUTHENTICATE: {
      return { ...state, isLoading: true };
    }
    case AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        identity: action.payload,
        error: null,
        alertMessage: null,
      };
    }
    case ADMIN_LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: null,
        identity: action.payload || {},
      };
    }

    case PERSIST_IDENTITY: {
      const { identity, needRefresh } = action.payload;
      return { ...state, identity, needRefresh };
    }

    case SET_CPAY_DETAILS: {
      return {
        ...state,
        isLoading: false,
        cpayDetails: action.payload,
        needUpdateCpayMethod: true,
      };
    }
    case SET_TRANSACTION_REFERENCE: {
      return { ...state, transactionReference: action.payload };
    }
    case GET_IFRAME_URL: {
      return { ...state, isLoading: true };
    }
    case GET_IFRAME_URL_SUCCESS: {
      return { ...state, isLoading: false, iframeUrl: action.url, error: null };
    }
    case GET_ACCOUNT_DETAILS_SUCCESS: {
      return { ...state, isLoading: false, accountDetails: action.payload, error: null };
    }
    case LIST_PAYMENT_METHODS_SUCCESS: {
      return { ...state, isLoading: false, paymentMethods: action.payload, error: null };
    }
    case SAVE_PAYMENT_METHODS: {
      return { ...state, isLoading: true };
    }
    case SAVE_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        cpayMethodUrl: action.payload.url,
        error: null,
        alertMessage: null,
      };
    }
    case GET_PAYMENT_DETAILS: {
      return { ...state, isLoading: true };
    }
    case GET_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        cpayDetails: action.payload,
        error: null,
        alertMessage: null,
      };
    }
    case UPDATE_PAYMENT_METHODS: {
      return { ...state, isLoading: true };
    }
    case UPDATE_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        needUpdateCpayMethod: false,
        error: null,
        alertMessage: null,
      };
    }
    case SUBMIT: {
      return { ...state, isLoading: true, error: null, alertMessage: null };
    }
    case SUBMIT_SUCCESS: {
      cleanSessionLater();
      return {
        ...initState,
        isLoading: false,
        initData: {},
        iframeUrl: null,
        dateList: debitDateOptions(3),
        identity: {},
        needRefresh: false,
        cpayDetails: {},
        transactionReference: null,
        cpayMethodUrl: null,
        error: null,
        alertMessage: null,
        formSent: true,
      };
    }
    case SET_ALERT_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        isLoading: false,
        cpayMethodUrl: null,
        cpayDetails: {},
        iframeUrl: null,
      };
    }
    case SET_RESPONSE_ERROR: {
      cleanSessionLater();
      return {
        ...state,
        initData: {},
        dateList: debitDateOptions(3),
        identity: {},
        needRefresh: false,
        isLoading: false,
        error: action.payload,
        cpayMethodUrl: null,
        cpayDetails: {},
        iframeUrl: null,
        transactionReference: null,
        alertMessage: null,
      };
    }
    default:
      return state;
  }
};
