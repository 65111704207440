import {
  ERROR,
  LOADING_START,
  LOADING_STOP,
  AUTHENTICATE,
  AUTHENTICATE_SUCCESS,
  SET_CPAY_DETAILS,
  SET_TRANSACTION_REFERENCE,
  SetErrorType,
  SECURE_CODE_SUCCESS,
  SECURE_CODE_FIRE,
  GET_IFRAME_URL,
  GET_IFRAME_URL_SUCCESS,
  SAVE_PAYMENT_METHODS,
  SAVE_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_SUCCESS,
  SUBMIT,
  SUBMIT_SUCCESS,
  ALERT_MESSAGE,
  SET_RESPONSE_ERROR,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  PERSIST_IDENTITY,
  LIST_PAYMENT_METHODS_SUCCESS,
  GET_ACCOUNT_DETAILS_SUCCESS,
  SAVE_BANK_ACCOUNT,
  LIST_PAYMENT_METHODS,
} from './types';
import { RemoteIdAuth } from '../config/types';

export const setError = (props): SetErrorType => {
  const { errorType, error } = props;
  return {
    type: ERROR,
    errorType,
    error,
  };
};

export const loadingStart = () => {
  return { type: LOADING_START };
};
export const loadingStop = () => {
  return { type: LOADING_STOP };
};

export const authenticate = (payload: RemoteIdAuth) => {
  return { type: AUTHENTICATE, payload };
};

export const authenticateSuccess = (payload: { [key: string]: string | {} }) => {
  return {
    type: AUTHENTICATE_SUCCESS,
    payload,
  };
};

export const setCpayDetails = (payload: {}) => {
  return { type: SET_CPAY_DETAILS, payload };
};

export const secureCodeSuccess = code => {
  return { type: SECURE_CODE_SUCCESS, code };
};

export const secureCodeFire = code => {
  return { type: SECURE_CODE_FIRE, code };
};

export const getIframeUrl = () => {
  return { type: GET_IFRAME_URL };
};

export const getIframeUrlSuccess = payload => {
  const { url } = payload;
  return { type: GET_IFRAME_URL_SUCCESS, url };
};

export const getAccountDetailsSuccess = payload => ({ type: GET_ACCOUNT_DETAILS_SUCCESS, payload });

export const saveBankAccount = () => ({ type: SAVE_BANK_ACCOUNT });

export const getPaymentMethods = () => ({
  type: LIST_PAYMENT_METHODS,
});

export const savePaymentMethods = payload => {
  return { type: SAVE_PAYMENT_METHODS, payload };
};

export const savePaymentMethodsSuccess = payload => {
  return { type: SAVE_PAYMENT_METHODS_SUCCESS, payload };
};

export const listPaymentMethodsSuccess = payload => ({
  type: LIST_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const getPaymentDetails = () => {
  return { type: GET_PAYMENT_DETAILS };
};

export const getPaymentDetailsSuccess = payload => {
  return { type: GET_PAYMENT_DETAILS_SUCCESS, payload };
};

export const updatePaymentMethods = () => {
  return { type: UPDATE_PAYMENT_METHODS };
};

export const updatePaymentMethodsSuccess = () => {
  return { type: UPDATE_PAYMENT_METHODS_SUCCESS };
};

export const submitForm = () => {
  return { type: SUBMIT };
};

export const submitFormSuccess = payload => {
  return { type: SUBMIT_SUCCESS, payload };
};

export const setTransactionReference = (payload: string) => {
  return { type: SET_TRANSACTION_REFERENCE, payload };
};

export const setAlertMessage = (payload: string | null) => {
  return { type: ALERT_MESSAGE, payload };
};

export const setErrorResponse = payload => {
  return { type: SET_RESPONSE_ERROR, payload };
};
export const adminLoginRequest = () => {
  return { type: ADMIN_LOGIN_REQUEST };
};
export const adminLoginSuccess = payload => {
  const { id_token, access_token } = payload;
  return { type: ADMIN_LOGIN_SUCCESS, payload: { accessToken: id_token || access_token, ...payload }};
};

export const persistIdentity = (
  payload: { identity: Record<string, any>; needRefresh: boolean } = { identity: {}, needRefresh: false },
) => {
  return { type: PERSIST_IDENTITY, payload };
};
