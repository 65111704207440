import { call, takeLatest, put } from 'redux-saga/effects';
import { AUTHENTICATE, ADMIN_LOGIN_REQUEST } from '../actions/types';
import { setError, authenticateSuccess, adminLoginSuccess, setAlertMessage } from '../actions';
import { authByRemoteId } from './api';
import { fetchSaga } from './fetchSaga';
import { getAuthService } from '../AuthService';

const authActions = {
  eResponse: authenticateSuccess,
  eError: setError,
  eAlert: null,
};

const apiAuth = fetchSaga(authActions, function* (payload): any {
  const response = yield call(authByRemoteId, { ...payload });
  return yield response.json();
});

function* initAuth(payload: Record<string, any>): any {
  yield call(apiAuth, payload);
}

function* userLoginSaga(): any {
  try {
    const authService = getAuthService();
    const user = yield call([authService, authService.completeLogin]);
    yield put(adminLoginSuccess(user))
  } catch (error) {
    yield put(setError(error))
    yield put(setAlertMessage((error as Error).message))
  }
}

function* watchAuthActions(): any {
  yield takeLatest(AUTHENTICATE, initAuth);
  yield takeLatest(ADMIN_LOGIN_REQUEST, userLoginSaga);
}

export { initAuth, apiAuth, userLoginSaga };
export default watchAuthActions;
