import { call, takeLatest, select } from 'redux-saga/effects';
import config, { HTTP } from '../config';
import { SUBMIT } from '../actions/types';
import { submitFormSuccess, setAlertMessage, setErrorResponse } from '../actions';

import { selectSubmitVals } from '../utilities/reselecter';
import { savePaymentMethod, submitApp } from './api';
import { fetchSaga } from './fetchSaga';
import { getUserId } from '../utilities';

const submitActions = {
  eResponse: submitFormSuccess,
  eError: setErrorResponse,
  eAlert: setAlertMessage,
};

export const apiSubmitForm = fetchSaga(
  submitActions,
  function* (): any {
    const {
      formData: { amount, paymentMethod, paymentType, debitDate, accountBsb, accountNumber, accountName },
      initData,
      identity,
      transactionReference,
    } = yield select(selectSubmitVals);

    const { applicationId } = initData;
    const userId = getUserId(initData, identity);
    const { accessToken } = identity;
    let methodId: string = paymentMethod === 'EP' ? null : paymentMethod;

    if (paymentMethod === 'NEW' && paymentType === 'DD') {
      const response = yield call(savePaymentMethod, {
        accessToken,
        userId,
        type: 'debit',
        source: config.appType,
        accountBsb,
        accountName,
        accountNumber,
      });
      const { ok } = response;
      if (ok) {
        const { id } = yield response.json();
        methodId = id;
      }
    }

    const submitObj: Record<string, any> = {
      accessToken,
      source: config.appType,
      amount: parseFloat(parseFloat(amount).toFixed(2)),
      userId,
      loanId: applicationId,
      transactionReference,
      methodId,
      paymentDate: debitDate === 'today' ? null : new Date(parseInt(debitDate)).toISOString(),
    };

    return yield call(submitApp, submitObj);
  },
  [{ code: HTTP.CREATED, message: 'success', event: 'success' }],
);

export function* formSubmission(): Generator<any> {
  yield call(apiSubmitForm);
}

function* watchFormActions(): Generator<any> {
  yield takeLatest(SUBMIT, formSubmission);
}

export default watchFormActions;
