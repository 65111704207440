import Config, { defaultHeaders } from '../config';
import { RemoteIdAuth } from '../config/types';
import { SENTRY_ERROR } from '../config/errorConfig';
import { paramsFromURL } from '../utilities';
import CustomError from '../components/CustomError';

const headers = { ...defaultHeaders };

// TODO think about how to sent error to sentry
const handleErrors = async (response: Response) => {
  const { status = 500, statusText = '', ok = false } = response;

  if (!ok && ![400, 401].includes(status)) {
    const body = await response.json();
    const message = body ? body.error : statusText;
    const error = new CustomError(`${status} ${message} with URL params ${JSON.stringify(paramsFromURL())}`);
    error.statusCode = status;
    error.name = status > 499 ? SENTRY_ERROR.SEVERITY.EMERGENCY : SENTRY_ERROR.SEVERITY.CRITICAL;
    throw error;
  }

  return response;
};

const { serviceUrl, appType } = Config;

export const authByRemoteId = async (payload: RemoteIdAuth): Promise<any> =>
  fetch(`${serviceUrl()}/identity/mobile/auth-by-id`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers,
  });

export const refreshToken = async (refreshToken: string): Promise<any> => {
  return fetch(`${serviceUrl()}/identity/refresh`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers,
  });
};

export const listCpayMethods = async (payload: Record<string, any>): Promise<any> => {
  const { accessToken, userId } = payload;
  return fetch(`${serviceUrl()}/payment-methods?userId=${userId}`, {
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
  }).then(handleErrors);
};

export const updatePaymentMethods = async (payload: Record<string, any>): Promise<any> => {
  const { accessToken, id, cardExpiry, userId } = payload;

  return fetch(`${serviceUrl()}/payment-methods/${id}?userId=${userId}`, {
    method: 'PUT',
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({ cardExpiry }),
  });
};

export const getCreditCardUrl = async (): Promise<Response> =>
  fetch(`${serviceUrl()}/payment-gateway/iframe?source=${appType}`, {
    method: 'GET',
    headers,
  });

export const savePaymentMethod = async (payload: Record<string, any>): Promise<any> => {
  const { accessToken } = payload;
  delete payload.accessToken;

  return fetch(`${serviceUrl()}/payment-methods`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
  });
};

export const getCpayDetails = async (payload: Record<string, any>): Promise<any> => {
  const { cpayMethodUrl, accessToken, userId } = payload;

  return fetch(`${cpayMethodUrl}?userId=${userId}`, {
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
  });
};

export const submitApp = async (payload: Record<string, any>): Promise<any> => {
  const { accessToken } = payload;
  delete payload.accessToken;

  return fetch(`${serviceUrl()}/payment-gateway/pay`, {
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  });
};

// TODO test only
export const testSubmitApp = async (payload: Record<string, any>): Promise<any> =>
  new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({ status: 400, message: 'some data' });
    }, 3000);
  });

export default {
  authByRemoteId,
  getCreditCardUrl,
  savePaymentMethod,
  getCpayDetails,
  submitApp,
  testSubmitApp,
};
