import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Col, Row, Heading, Hyperlink, withResponsive } from '@brighte/brighte-one-native';
import { actions, useNavigation } from '@brighte/brighte-one-core';
import { StyledText } from '../styles/elemets/styledTexts';
import config from '../config';
import { submitForm } from '../actions';
import congrats from '../assets/icons/congrats.svg';

const Submit = () => {
  const dispatch = useDispatch();
  const [isSubmit, setSubmit] = useState(false);
  const { previousPage } = useNavigation();

  const { initData, alertMessage, isLoading } = useSelector(
    state => state.appData || { initData: {}, error: null, alertMessage: null, isLoading: false },
  );

  useEffect(() => {
    if (!isSubmit) {
      dispatch(submitForm());
      setSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmit && !isEmpty(alertMessage) && !isLoading) {
      dispatch(
        actions.setCurrentPage({
          currentPage: Submit.name,
          previousPage,
          formName: config.formId,
          pageName: 'Payment',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit, alertMessage, isLoading, dispatch]);

  return (
    <>
      {!isEmpty(initData) ? (
        <StyledText text="Submitting ..." />
      ) : (
        <>
          <Row id="row-1">
            <Col halign="center" id="col-1">
              <Heading text={`Thank you for making the payment`} level={2} />
              <StyledText>
                {'If you require any further assistance, do not hesitate to call us on 1-300-274-448. You can also '}
                <Hyperlink text="login to your" href="https://portal.brighte.com.au/" />
                {' portal to check your balance.'}
              </StyledText>
            </Col>
          </Row>
          <Row id="row-2">
            <Col halign="center" id="col-2">
              <img src={congrats} alt="Thank you!" />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withResponsive(Submit);
