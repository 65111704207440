import React from 'react';
import styled from 'styled-components';
import { Text, theme } from '@brighte/brighte-one-native';
import { applyResponsiveness } from '@brighte/brighte-one-core/src/helpers';

const ErrorBoundary = styled.div`
  background-color: ${theme.WhiteColor};
  padding: ${({ responsiveClass }) => (applyResponsiveness(responsiveClass, 'SM') ? '20px' : ' 40px')};
  border-top: 5px solid #00D299;
  border-radius: 4px;
  ${props => props.customStyle};
`;

const styledText = props => <Text customStyle={{ margin: '15px 0 30px 0', lineHeight: 30 }} {...props} />;

ErrorBoundary.Text = styledText;

ErrorBoundary.defaultProps = {};

export default ErrorBoundary;
