import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { theme } from '@brighte/brighte-one-native';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    body {
        height: 100%;
        padding: 0;
        background-color: ${theme.LightBackground};
        font-family: ${theme.fonts.regular};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    input:focus {
        outline: none;
    }
`;

export default GlobalStyle;
