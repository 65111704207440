import { all, fork } from 'redux-saga/effects';

import watchAuthActions from './auth.saga';
import watchPaymentActions from './payment.saga';
import watchFormActions from './form.saga';

export default function* rootSaga(): any {
  yield all([
    fork(watchAuthActions),
    fork(watchPaymentActions),
    fork(watchFormActions),
  ]);
}
