import isArray from 'lodash/isArray';
import config from '../config';

export const normaliseFormData = (formData: any): Record<string, string> => {
  const { fields } = formData[config.formId];
  const results = {};

  for (const ele in fields) {
    const { value } = fields[ele];
    results[ele] = isArray(value) ? value.join('') : value;
  }
  return results;
};

export const selectSubmitVals = ({ formData, appData }) => {
  const { initData, identity, cpayDetails, transactionReference } = appData;
  const after = normaliseFormData(formData);
  return { formData: after, initData, identity, cpayDetails, transactionReference };
};

export default { selectSubmitVals, normaliseFormData };
