import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Col, Row, Text, withResponsive, SecureCodeContainer, Heading, Hyperlink } from '@brighte/brighte-one-native';
import { actions } from '@brighte/brighte-one-core';
import get from 'lodash/get';
import { StyledText } from '../styles/elemets/styledTexts';
import { authenticate, authenticateSuccess, loadingStart, loadingStop } from '../actions';
import { authByRemoteId } from '../saga/api';
import config from '../config';

const SecureCode = () => {
  const { identity, initData } = useSelector(state => state.appData);
  const dispatch = useDispatch();
  const firstName = get(identity, 'user.firstName', '');
  const { userId: remoteId } = initData;

  useEffect(() => {
    if (!isEmpty(remoteId)) {
      dispatch(authenticate({ remoteId }));
    }
  }, [remoteId, dispatch]);

  const resentCode = (): void => {
    const { code } = identity;
    if (code !== 'AUTHENTICATED') {
      dispatch(authenticate({ remoteId }));
      dispatch(actions.setFieldAttributes('secureCode', config.formId, { value: '' }));
    }
  };

  const triggerApiAction = async (code: Array<string>, setFieldAttributes: Function): Promise<void> => {
    try {
      dispatch(loadingStart());
      setFieldAttributes({
        valid: false,
      });

      const result = await authByRemoteId({
        remoteId,
        authCode: code.join(''),
        scope: config.cpayScope,
      }).then((response: Response) => response.json());

      if (result && result.code === 'AUTHENTICATED') {
        dispatch(authenticateSuccess({ ...result }));
        setFieldAttributes({
          valid: true,
          status: 'verified',
          timestamp: Date.now(),
        });
      } else {
        setFieldAttributes({
          valid: false,
          errorMessage: 'Incorrect secure code. Please try again.',
          status: 'invalid',
          timestamp: Date.now(),
        });
      }
    } catch (error) {
      setFieldAttributes({
        valid: false,
        errorMessage: 'Please try again later',
        status: 'error',
        timestamp: Date.now(),
      });
    }
    dispatch(loadingStop());
  };

  return (
    <>
      {isEmpty(identity) ? (
        <StyledText text="Initialising ..." />
      ) : (
        <>
          <Row id="row-1">
            <Col halign="center" id="col-1">
              {firstName && <Heading text={`${firstName}, we're here to help!`} level={2} />}
              <StyledText text="We've made it easy for you to make one-off payments that suit your personal situation." />
            </Col>
          </Row>
          <Row id="row-2">
            <Col id="col-2">
              <Text customStyle={{ lineHeight: 25 }}>
                {`But first, please enter the secure code you received on your mobile so we're sure its you. `}
                <Hyperlink text="Resend code" onClick={() => resentCode()} />.
              </Text>
            </Col>
          </Row>
          <Row id="row-3">
            <Col id="col-3">
              <SecureCodeContainer
                id="auth-code"
                fieldName="secureCode"
                label="Secure Code"
                triggerApi={triggerApiAction}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withResponsive(SecureCode);
