import { Log, User, UserManager } from 'oidc-client';
import Config, { adminScopes } from './config';

export class AuthService {
  public userManager: UserManager;

  constructor() {
    this.userManager = new UserManager({
      authority: `${Config.serviceUrl()}/..`,
      client_id: Config.appType,
      client_secret: Config.appSecret,
      redirect_uri: Config.appUrl(),
      scope: ['openid', ...adminScopes].join(' '),
      response_type: 'code',
      loadUserInfo: false,
      revokeAccessTokenOnSignout: true,
    });

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    this.userManager.signinRedirect();
    return Promise.resolve();
  }

  public async completeLogin(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}

export const getAuthService = () => {
  return new AuthService();
};
