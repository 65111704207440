import { scroller } from 'react-scroll';

export const scrollTo = async (fieldName: string, offset: number = -150): Promise<void> => {
  scroller.scrollTo(fieldName, {
    duration: 800,
    delay: 0,
    smooth: true,
    offset,
  });
};

export default () => {
  if (document.querySelectorAll('[data-error="true"]').length !== 0) {
    scrollTo(document.querySelectorAll('[data-error="true"]')[0].id);
    return;
  }

  if (document.querySelectorAll('[ data-brighte-class="brighte-one-form-container"]').length !== 0) {
    scrollTo(document.querySelectorAll('[ data-brighte-class="brighte-one-form-container"]')[0].id);
  }
};
