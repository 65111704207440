import { call, put, race, delay } from 'redux-saga/effects';
import Config, { HTTP, alerts } from '../config';
import { ERROR } from '../config/errorConfig';

interface Entity {
  eResponse: Function;
  eError: Function;
  eAlert: Function | null;
}

interface CustomHandler {
  code: number;
  message?: string;
  event?: 'alert' | 'error' | 'success';
}

export const fetchSaga = (entity: Entity, api: any, customHandlers: Array<CustomHandler> = []): any =>
  function* ({ payload } = { payload: null }): any {
    const { timeout: delayTime } = Config;
    const { eResponse, eAlert, eError } = entity;
    const { UNPROCESSABLE_ENTITY, UNAUTHORIZED, BAD_REQUEST, CONFLICT, NOT_FOUND } = alerts;

    try {
      const { response, timeout } = yield race({
        response: call(api, payload),
        timeout: delay(delayTime),
      });

      if (timeout) {
        // Doesn't log to sentry
        yield put(eError(ERROR.TIMEOUT.CODE));
        throw new Error(ERROR.TIMEOUT.CODE);
      }

      if (response && response instanceof Response) {
        const { status } = response;
        const result = response.text().then(resp => {
          try {
            return resp ? JSON.parse(resp) : null;
          } catch {
            return resp;
          }
        });

        for (const cHandler of customHandlers) {
          const { code, message, event } = cHandler;
          if (status === code) {
            switch (event) {
              case 'success':
                return yield put(eResponse(message || (yield result)));
              case 'alert':
                return yield put(eAlert ? eAlert(message || result) : eError(message || (yield result)));
              default:
                return yield put(eError(message || (yield result)));
            }
          }
        }

        if (!eAlert) {
          return yield put(eResponse(yield result));
        }

        // general cases yield alert
        switch (status) {
          case HTTP.UNPROCESSABLE_ENTITY:
            return yield put(eAlert(UNPROCESSABLE_ENTITY));
          case HTTP.BAD_REQUEST:
            return yield put(eAlert(BAD_REQUEST));
          case HTTP.UNAUTHORIZED:
            return yield put(eAlert(UNAUTHORIZED));
          case HTTP.FORBIDDEN:
            return yield put(eAlert(alerts.FORBIDDEN));
          case HTTP.CONFLICT:
            return yield put(eAlert(CONFLICT));
          case HTTP.NOT_FOUND:
            return yield put(eAlert(NOT_FOUND));
        }

        yield put(eResponse(yield result));
      } else {
        yield put(eResponse(response));
      }
    } catch (e) {
      // This triggers ErrorPageBoundary and adds to store
      yield put(eError({ errorType: 'Fetch exception', error: e }));
    }
  };
