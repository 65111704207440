import React from 'react';
import PropTypes from 'prop-types';
import {
  HorizontalLine,
  StyledClientText,
  InlineContainer,
  StyledTextContainer,
} from '../../styles/elemets/styledTexts';
import { Heading } from '@brighte/brighte-one-native';

const Verification = ({ applicationId, userId }) => {
  return (
    <>
      <Heading
        level={2}
        customStyle={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '20px',
          fontWeight: 'bold',
        }}
        text={'One-off payment'}
      />
      <HorizontalLine />

      <InlineContainer>
        <StyledTextContainer>
          <StyledClientText customStyle={{ color: '#BCC0C7', fontWeight: 'bold' }}>Client ID</StyledClientText>
        </StyledTextContainer>
        <StyledTextContainer textAlign="right">
          <StyledClientText customStyle={{ fontWeight: 'bold' }}>{userId}</StyledClientText>
        </StyledTextContainer>
      </InlineContainer>
      <HorizontalLine />
      <InlineContainer>
        <StyledTextContainer>
          <StyledClientText customStyle={{ color: '#BCC0C7', fontWeight: 'bold' }}>Account ID</StyledClientText>
        </StyledTextContainer>
        <StyledTextContainer textAlign="right">
          <StyledClientText customStyle={{ fontWeight: 'bold' }}>{applicationId}</StyledClientText>
        </StyledTextContainer>
      </InlineContainer>
      <HorizontalLine />
    </>
  );
};

Verification.propTypes = {
  userId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
};

export default Verification;
