import styled from 'styled-components';
import { theme } from '@brighte/brighte-one-native';

const Copyright = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  ${props => props.customStyle}
`;

const TextCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  ${props => props.customStyle}
`;
Copyright.TextCell = TextCell;

Copyright.defaultProps = {
  theme: {
    blockBorderRadius: theme.blockBorderRadius,
    FormElements: theme.FormElements,
  },
};

export default Copyright;
